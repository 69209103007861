body {
  margin: 0;
  overflow: hidden;
}

.logo {
  position: absolute;
  width: 124px;
  margin: 10px;
  bottom: 0;
  right: 0;
}

#controls {
  right: calc(((75% - 144px)/3)*2);
}

#looks {
  right: calc((75% - 144px)/3);
}

.controls {
  display: none;
  position: absolute;
  bottom: 0px;
  margin: auto;
  text-align: center;
  height: 25%;
}

.controls > * {
  height: 50%;
}

.controls img {
  height: 100%;
  opacity: 0.2;
}